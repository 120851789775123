import type { Namespace, KeyPrefix } from "i18next";

import { getI18n } from "react-i18next";

type Language = string;

type Options = {
  lng?: Language | Language[] | null;
  prefix?: KeyPrefix<string> | null;
};

export default getI18n();

const defaultOptions = {
  lng: null,
  prefix: null,
};

export const withNamespace = (namespace: Namespace, options: Options = defaultOptions) => {
  const i18n = getI18n();
  const { lng = null, prefix = null } = options;

  // @ts-ignore
  return i18n.getFixedT(lng, namespace, prefix);
};
